<template>
  <v-combobox
      v-model="selected"
      :items="items"
      item-text="name"
      label="Выберите шлиф"
      :rules="rules"
      :disabled="this.sample == null"
      @change="itemSelected($event)"
  ></v-combobox>
</template>

<script>
import selectorMixin from "@/components/modal/selector/mixin/selector-mixin";

export default {
  name: "ThinSelector",
  mixins: [selectorMixin],
  props: ['sample'],
  computed: {
    items() {
      if (this.sample != null)
        return this.sample.thin_sections
      else
        return []
    }
  },
  watch: {
    sample() {
      this.selected = null
      this.itemSelected(this.selected)
    }
  }
}
</script>
