const selectorMixin = {
    data() {
        return {
            rules: [v => v != null || 'Это поле не должно быть пустым'],
            selected: null
        }
    },
    methods: {
        itemSelected(item) {
            this.$emit('input', item)
        }
    }
}

export default selectorMixin